import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SectionsModule } from './sections/sections.module';
import { HeaderComponent } from './shared/header/header.component';
import { EventMessageComponent } from './shared/messages/event-message.component';
import { HomeComponent } from './home/home.component';
import { RequestInterceptor } from './core/interceptors/request-interceptor';
import { RouteModalComponent } from './home/route-modal/route-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    EventMessageComponent,
    RouteModalComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    SectionsModule,
    CommonModule,
    FormsModule,
    RouterModule,
    AppRoutingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


