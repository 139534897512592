import { Injectable } from '@angular/core';
import jwt_decode from "jwt-decode";
import { User } from '../model/user';

const TOKEN_KEY = 'token';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  
  

  constructor() { }

  getItemString(key: string) {
    return localStorage.getItem(key);
  }
  stringifyItem(key: string, item: any) {
    localStorage.setItem(key, JSON.stringify(item));
  }

  parseItem<T>(key: string) {
    return JSON.parse(localStorage.getItem(key)) as T;
  }

  setItem(key: string, item: any) {
    localStorage.setItem(key, item);
  }

  getItemJSON(key: string) {
    if(localStorage.getItem(key)){
      return JSON.parse(localStorage.getItem(key));
    }else{
      return null;
    }    
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }


  clean(): void {
    window.sessionStorage.clear();
  }


  
  public setToken(token: string) {   
    localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return localStorage.getItem(TOKEN_KEY);
   
  }



  public isLoggedIn(): boolean {
    const user = window.sessionStorage.getItem(TOKEN_KEY);
    if (user) {
      return true;
    }

    return false;
  }

}

