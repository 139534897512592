import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EventMessage } from 'src/app/core/messages/event.message';

@Component({
  selector: 'app-route-modal',
  templateUrl: './route-modal.component.html',
  styleUrls: ['./route-modal.component.scss']
})
export class RouteModalComponent implements OnInit {

  @Input()
  route: any = {
    name: null,
    path: null,
    permissions: null,
    url: null
  };


  @Output() routeChange: EventEmitter<any> = new EventEmitter<any>();


  constructor(private eventMessage: EventMessage) { }

  ngOnInit(): void {
    this.eventMessage.subscribe('toggleRouteModal', callback => {

      if(callback.action == 'close'){
        this.close();
      }else{
        if (callback.action == 'edit') {
          this.isEditable = false;
        } else {
          this.isEditable = true;
        }
        this.toggleModal(callback.node);
      }
      
    });
  }

  modalVisible: boolean = false;
  isEditable: boolean = true;



  save() {
    this.routeChange.emit(this.route);
  }

  @ViewChild('modalRoute') modalRoute: ElementRef;
  @ViewChild('modalLimit') modalLimit: ElementRef;


  toggleModal(node: string) {
    if(node == 'route'){
      if (this.modalRoute.nativeElement.className === 'hidden' || this.modalRoute.nativeElement.className === 'md:hidden') {
        this.modalRoute.nativeElement.className = ''
      } else {
        this.modalRoute.nativeElement.className = 'hidden'
      }
    }else   if(node == 'limit'){
      if (this.modalLimit.nativeElement.className === 'hidden' || this.modalLimit.nativeElement.className === 'md:hidden') {
        this.modalLimit.nativeElement.className = ''
      } else {
        this.modalLimit.nativeElement.className = 'hidden'
      }
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event) {
    if ( this.isActive() && event.keyCode === 27) {
      this.modalRoute.nativeElement.className = 'hidden';
      this.modalLimit.nativeElement.className = 'hidden';
    }
    if ( this.isActive() && event.keyCode === 13) {
      this.save();
    }
  }

  close(){
    this.modalRoute.nativeElement.className = 'hidden';
    this.modalLimit.nativeElement.className = 'hidden';
  }

  isActive(){
    return this.modalRoute.nativeElement.className == '' || this.modalLimit.nativeElement.className == '';
  }

}
