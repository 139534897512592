import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventMessage } from '../core/messages/event.message';
import { GatewayService } from '../core/services/gateway.service';
import { RouteModalComponent } from './route-modal/route-modal.component';
import { OnOk } from '../core/core';
import { LocalStorageService } from '../core/services/local-storage.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit,OnOk {

  public article: any;
  public published_at: any;
  @ViewChild('container') d1: ElementRef;

  currentRoute: any = {
    name: null,
    path: null,
    permissions: null,
    url: null,
    limit:  {
      "limit" : 11000000,
      "quota" : 1001,
      "refreshInterval" : 60,
      "type" : "user"
  }
  };

  index: number;
  name: string;

  constructor(
    private route: ActivatedRoute,
    private eventMessage: EventMessage,
    private storageService: LocalStorageService,
    private readonly gatewayService: GatewayService) { }


  onOk(): void {
    this.gatewayService.deleteroute(this.name).subscribe(result => {  
      this.routes.splice(this.index,1);
      });
  }



   public routes : any = [];

  ngAfterViewInit() {


  }


  ngOnInit(): void {

    const token = this.route.snapshot.params['token'];
    if(token != null){
      this.storageService.setToken(token);
    }
        
   this.gatewayService.getroute().subscribe(result => {
     this.routes = result;   
     
    });
  }


  deleteroute(index: number, name: string){
    this.name = name;
    this.index = index;
     this.eventMessage.confirm('Are you sure?',this);

  }


  openModal(): void {
   this.eventMessage.toggleRouteModal('route','');
  }

  closeModal(): void {
    this.eventMessage.toggleRouteModal('route','');
  }

  selectRoute(route: any){
    this.currentRoute = route;
    this.eventMessage.toggleRouteModal('route','edit');
  }

  selectLimit(route: any){
    this.currentRoute = route;
    this.eventMessage.toggleRouteModal('limit','edit');
  }

  addRoute(){
    this.currentRoute = {
      name: null,
      path: null,
      permissions: null,
      url: null,
      limit:  {
        "limit" : 11000000,
        "quota" : 1001,
        "refreshInterval" : 60,
        "type" : "user"
    }
    };
    this.eventMessage.toggleRouteModal('route','add');
  }

  onSave(route:any){    
    this.gatewayService.postroute(route).subscribe(r=>{
      this.eventMessage.toggleRouteModal('','close');
      this.eventMessage.info('saved!');  
    });
  }
 


}

