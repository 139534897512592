import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class GatewayService {

  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient,
  ) { }

  getroute(): Observable<any> {
    return this.http.get<any[]>(`${environment.URL_API}/admin/route`).pipe(
      catchError(e => {
        return throwError(e);
      })
    )
  }

  postroute(route: any): Observable<any> {
    return this.http.post<any[]>(`${environment.URL_API}/admin/route`, route).pipe(
      catchError(e => {
        return throwError(e);
      })
    )
  }

  deleteroute(name: string): Observable<any> {
    return this.http.delete<any[]>(`${environment.URL_API}/admin/route/`+name).pipe(
      catchError(e => {
        return throwError(e);
      })
    )
  }


}
